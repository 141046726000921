import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/auxiliary/layout";
import { Container, Grid } from "theme-ui";
import SectionHeader from "components/section-header";
export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO
          title="Garaaz - Marketplace & SaaS for automobile spare parts"
          description="Omni channel marketplace and SaaS for distribution of spare parts in the automobile industry"
          keywords="garaaz, spare parts, automobile, svan autotech, automobile"
        />

        <section sx={{ variant: "section.feature" }}>
          <Container>
            <SectionHeader slogan="" title="Return & Cancellation Policy" />
            <h2>
              <strong>Return & Cancellation Policy</strong>
            </h2>

            <p>
              At Garaaz, customer satisfaction is a priority. We offer a
              flexible return and cancellation policy to ensure a smooth
              experience for all our customers.
            </p>

            <h3>
              <strong>1. Return Policy</strong>
            </h3>

            <p>
              Returns are accepted within <strong>7 days</strong> of delivery
              under the following conditions:
            </p>
            <ul>
              <li>
                Items must be in their original condition, unused, and in
                original packaging.
              </li>
              <li>
                Custom or special-order parts are non-returnable unless
                defective.
              </li>
            </ul>

            <p>
              <strong>Steps to Initiate a Return:</strong>
            </p>
            <ol>
              <li>
                Contact our support team at <strong>founders@garaaz.com</strong>{" "}
                with your order details and reason for return.
              </li>
              <li>
                Receive a Return Authorization (RA) confirmation and shipping
                instructions.
              </li>
              <li>
                Ship the item back securely, including all original accessories.
              </li>
              <li>
                Refunds will be processed within{" "}
                <strong>7-10 business days</strong> after inspection.
              </li>
            </ol>

            <p>
              <strong>Note:</strong> Shipping costs for returns are borne by the
              customer unless the item is defective or incorrect. A restocking
              fee of <strong>10%</strong> may apply for non-defective returns.
            </p>

            <h3>
              <strong>2. Cancellation Policy</strong>
            </h3>

            <p>
              Orders can be canceled <strong>before dispatch</strong> without
              any charges. If the order has already been shipped, customers must
              follow the return process.
            </p>

            <p>
              <strong>Note:</strong> Garaaz may cancel orders due to
              unavailability, pricing errors, or payment issues. In such cases,
              customers will be notified, and full refunds will be provided.
            </p>

            <h3>
              <strong>3. Defective or Damaged Items</strong>
            </h3>

            <p>
              If you receive a defective or damaged item, please contact us
              immediately with evidence (photos or videos) at{" "}
              <strong>founders@garaaz.com</strong>. We will arrange a
              replacement or full refund, including shipping costs.
            </p>

            <h3>
              <strong>Contact Information for Returns and Cancellations</strong>
            </h3>

            <p>
              For any queries regarding returns or cancellations, please
              contact:
            </p>

            <ul>
              <li>
                Email: <strong>founders@garaaz.com</strong>
              </li>
              <li>
                Phone: <strong>+91-9672911333</strong>
              </li>
              <li>
                Support Hours:{" "}
                <strong>Monday to Saturday, 9 AM to 6 PM IST</strong>
              </li>
            </ul>
          </Container>
        </section>
      </Layout>
    </StickyProvider>
  );
}
